import './libs/globals'

// Lorsque le document est prêt, mais tout n'est pas encore loadé ou affiché

$(document).ready(function () {

});

// Après que les images soient loadés
$(window).on('load', function () {

    setTimeout(function () {
        $("#loading img").fadeOut("fast");
    }, 50);
    setTimeout(function () {
        $("#loading").fadeOut("fast");
    }, 75);

    //emergence
    emergence.init({
        elemCushion: 0.2,
        reset: false
    });

    //initial check
    emergence.engage();

    //carrousel
    var owl = $(".owl-carousel");
    owl.owlCarousel({
        loop:true,
        nav: true,
        items: 1,
        //callbacks: true,
        //autoplay: true,
        //autoplaySpeed: 6000,
        smartSpeed: 500,
        //dots: false,
        dotsContainer: '#carousel-custom-dots'
        //responsive:{
        //    0:{
        //        items:1
        //    },
        //    600:{
        //        items:1
        //    },
        //    1000:{
        //        items:1
        //    }
        //}
    });

    // Go to the next item
    $('.g-carrousel__nav__button--right').click(function() {
        owl.trigger('next.owl.carousel');
        //callback();
    });
    $('.g-carrousel__nav__button--left').click(function() {
        owl.trigger('prev.owl.carousel');
        //callback();
    });



    //bubbles
    //var nbr_bubbles = 100;
    var bubble_max_width = 16;
    var dark_mode = false;
    var with_blur = false;
    var with_blob = false;

    var n = Date.now();

    function addBubbles(divElement, nbrBubbles){
        var i;
        for (i = 0; i < nbrBubbles; i++) {
            $(divElement).append('<div class="bubble"></div>');
        }

        $('.bubble').each(function(){
            var q = Math.floor((Math.random() * 3) + 0);
            var r = Math.floor((Math.random() * 5) + 1);
            var s = Math.floor((Math.random() * bubble_max_width) + 5)+'px';
            $(this)
                .attr('data-q', q).attr('data-r', r)
                .css('top', Math.floor((Math.random() * 110) + -10)+'%')
                .css('left', Math.floor((Math.random() * 110) + -10)+'%')
                .css('width', s).css('height', s);

        });
    }

    //for mobile and tablet
    //if(window.outerWidth < 1000){

        addBubbles(".g-cartes", 75);
        addBubbles(".g-section__contact", 75);


        function bubblesMobile(){
            //alert('yoyo');
            //$(function(){
            //if (Date.now() - n > 100) {
            n = Date.now();

            var midx = $(window).width()/2;
            var midy = $(window).height()/2;
            var nx = $(document).scrollTop() - midx;
            var ny = $(document).scrollTop() - midy;

            $('.bubble').each(function(){
                var t = $(this);
                var q = t.data('q');
                var r = t.data('r');
                var s = nx*0.03*q;
                if(s <= 0){
                    s = s*-1;
                }
                t.css('transform', 'translate3D('+(nx*0.05*q)+'px, '+(ny*0.07*r)+'px, 0)');

            });
            //}
            //});
        }

        $(document).on('scroll', bubblesMobile); // for mobile
        //$(document).addEventListener('scroll', bubblesMobile); // for mobile
        //$(window).on('scroll', bubblesMobile);

        //for desktop

    /*
    }else{

        addBubbles(".g-cartes", 75);
        addBubbles(".g-section__contact", 75);

        //$(function(){
        $(window).on('mousemove', function(e){

            if (Date.now() - n > 100) {
                n = Date.now();

                var midx = $(window).width()/2;
                var midy = $(window).height()/2;
                var nx = e.pageX - midx;
                var ny = e.pageY - midy;

                $('.bubble').each(function(){
                    var t = $(this);
                    var q = t.data('q');
                    var r = t.data('r');
                    var s = nx*0.03*q;
                    if(s <= 0){
                        s = s*-1;
                    }
                    t.css('transform', 'translate3D('+(nx*0.05*q)+'px, '+(ny*0.07*r)+'px, 0)');

                });
            }

        });
        //});
        */
    //}


    //tables
    $('table').each(function(){
        var table = $(this);
        var tableRow = table.find('tr');
        table.find('td').each(function(){

            //$(this).hide();
            var tdIndex = $(this).index();

            if ($(tableRow).find('th').eq(tdIndex).attr('data-label')) {
                var thText = $(tableRow).find('th').eq(tdIndex).data('label');
            } else {
                var thText = $(tableRow).find('th').eq(tdIndex).text();
            }
            $(this).attr('data-label', thText + ':');
        });
    });

    //show CTA
    $(window).scroll(function() {
        var scrollTop = $(window).scrollTop();
        var windowHeight = $("body").height() - $(window).height();

        var headerHeight = $("header").height() + $('.g-title').height();
        var footerHeight = $("footer").height();


        //console.log(windowHeight - scrollTop);
        if((scrollTop >= headerHeight) && (windowHeight - scrollTop >= footerHeight)){
            $('.g-facebook-share').addClass('active');
        }else{
            $('.g-facebook-share').removeClass('active');
        }
    });


    //check if empty form
    var filledFields= true;
    $('form input').on('change', function() {
        var form = $(this).closest('form');
        form.find('input, textarea').each(function() {
            if($(this).val().length == 0){
                filledFields = false;
                //console.log($(this).val());
            }else{
                filledFields = true;
            }
        });
        if(filledFields && (form.find('#conditions').is(':checked')) ){
            form.find('.g-button').removeClass('disabled');
        }else{
            form.find('.g-button').addClass('disabled');
        }
    });

    //CONTACT
    $('body').on('submit', '#g-contact-form', function (e) {

        e.preventDefault();

        var contactForm = $(this);

        if ( contactForm.find('#honey').val().length !== 0 ) { return false; }
        let formData = new FormData($(this)[0]);

        contactForm.find('.g-button').addClass('loading');

        $.ajax({
            method: "POST",
            url: wp_vars.ajax_url,
            async: false,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            processData: false,
            data: formData,

            // success
            success: function(){

                contactForm.find('textarea').val('');
                contactForm.find('input').val('');

                // disable form
                setTimeout(function(){
                    contactForm.find('.g-button').addClass('hidden').removeClass('loading');
                    contactForm.addClass('disabled');
                    setTimeout(function(){ // show success message
                        contactForm.find('.g-form-success').addClass('active');
                    }, 1000);
                }, 2500);

                console.log('success contact');
            }

        }).fail(function(){

            setTimeout(function(){
                contactForm.find('.g-button').removeClass('hidden').removeClass('loading');
                contactForm.removeClass('disabled');
                setTimeout(function(){ // show success message
                    contactForm.find('.g-form-fail').addClass('active');
                }, 1000);
            }, 2500);

            console.log('fail contact');
        });
        return false;


    });


    //select filter events
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };
    $('.g-select-filters__fields select').on('change', function(){

        //posts
        var postSelectValue = $('#categories').val();


        var url = window.location.href;
        url = url.split('?')[0];
        url = url + "?categorie=" + postSelectValue;

        window.location.replace(url);

    });

    $('.g-burger').on('click', function(){
        $(this).toggleClass('active');
        $('.g-mobile-navigation').toggleClass('active');
        $('.big-col.big-col--nav > a img').toggleClass('active');
    });



});